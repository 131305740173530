import { IUseClientApproval } from 'models/composables/useClientApproval.model';
import ClientsApprovalModal from 'components/modals/ClientsApprovalModal.vue';
import ClientsApprovalSuccessModal from 'components/modals/ClientsApprovalSuccessModal.vue';
import { IModalManager } from 'shared/models/modalManager.model';
import ModalManager from 'shared/services/modalManager.service';
import { AccountApiService } from 'services/api/accountApi.service';
import Notificator from 'shared/services/notificator.service';
import { EClientApprovalType } from 'enums/client/clientApprovalType.enum';

export function useClientApproval(): IUseClientApproval {
  const modalManager = process.client ? inject<IModalManager>(ModalManager.getServiceName()) : undefined;
  const notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined;

  function showClientApprovalSuccessModal(): void {
    const modalName = 'ClientApprovalSuccessModal';
    modalManager.show({
      bind: {
        title: 'Заявка успешно отправлена',
        'click-to-close': true,
        zIndex: 300,
        name: modalName,
      },
      component: ClientsApprovalSuccessModal,
      on: {
        close() {
          modalManager.hide(modalName);
        },
        ok() {
          modalManager.hide(modalName);
        },
      },
    });
  }

  function onCLickClientApprovalModal(text: EClientApprovalType): void {
    const modalName = 'ClickClientApprovalModal';
    modalManager?.show({
      bind: {
        title: `Заявка на подключение ${text}`,
        'click-to-close': true,
        zIndex: 300,
        name: modalName,
      },
      component: ClientsApprovalModal,
      on: {
        close() {
          modalManager.hide(modalName);
        },
        async ok(user) {
          modalManager.hide(modalName);

          try {
            await AccountApiService.submitUserApproval({
              ...user,
              supplier: text === EClientApprovalType.Supplier,
            });
            showClientApprovalSuccessModal();
          } catch (error) {
            console.error(error);
            notificator?.showNotification('Произошла ошибка отправки заявки. Попробуйте заново');
          }
        },
      },
    });

    // Вставляет в DOM скрипт для отправки данных с формы в Битрикс24
    (function (w, d, u) {
      const s = d.createElement('script');
      s.setAttribute('data-b24-form', 'inline/7/wn2b8n');
      s.setAttribute('data-skip-moving', 'true');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      const existingScript = d.querySelector('script[data-b24-form="inline/7/wn2b8n"]');
      if (!existingScript) {
        const h = d.getElementsByTagName('script')[0];
        h?.parentNode?.insertBefore(s, h);
      } else {
        const firstScript = d.getElementsByTagName('script')[0];
        if (existingScript !== firstScript) {
          existingScript?.parentNode?.insertBefore(existingScript, firstScript);
        }
      }
    })(window, document, 'src/shared/public/scripts/b24form-loader-7.js');
  }

  return {
    showClientApprovalSuccessModal,
    onCLickClientApprovalModal,
  };
}
